import { Chip, alpha } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { HeaderTitle, TitleP } from "../components/styled/Styled";
import { featuresData } from "../constants/siteData";
import { GenericButton } from "../components/buttons/GenericButton";
import { WalletLink } from "../constants/links";
import { getLocation } from "../utils/Utils";

export const MobileApp = () => {
  return (
    <Wrapper className="gradient1 combDark" id="wallet">
      <div className="container whiteColor">
        <TitleWrapper className="flex flexColumn gap10">
          <div>
            <Chip
              data-aos-duration="1000"
              data-aos="zoom-in-up"
              sx={{
                fontSize: 12,
                mb: 1,
                backgroundColor: "#ffe7ca",
              }}
              label="Your Financial Companion"
            />
          </div>
          <HeaderTitle>
            <span className="lightGoldColor">Everpesa</span> Invest Features
          </HeaderTitle>
          <TitleP
            className="font12 lightGoldColor"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            Invest & Earn while Contributing to a Greener Future. 
            Join your friends in using Everpesa and be part of a 
            community committed to sustainable investing.
          </TitleP>
        </TitleWrapper>

        <GridWrapper
          data-aos="fade-up"
          data-aos-delay="500"
          data-aos-duration="1500"
        >
          {featuresData.map((val, i) => (
            <CardItem data={val} key={i} index={i} />
          ))}
        </GridWrapper>

        {getLocation() === "/" && (
          <div className="textCenter">
            <GenericButton className="lightGoldBg" to={WalletLink}>
              Everpesa Invest
            </GenericButton>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const CardItem = ({ data, index }) => {
  const { title, Icon, desc } = data;
  const width = 30;
  return (
    <CardWrapper index={index} className="flex gap20 radius8 animate pointer">
      <div>
        <Icon
          className="lightGoldColor"
          style={{
            height: width,
            width: width,
            marginTop: 10,
          }}
        />
      </div>

      <div>
        <p className="semiBold lightGoldColor">{title}</p>
        <p
          className="font13 light"
          style={{ lineHeight: 1.8, marginBottom: 20 }}
        >
          {desc}
        </p>
      </div>
    </CardWrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  margin: 50px 0;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
  }
`;

const TitleWrapper = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  padding: 10px 20px;
  line-height: 3rem;
  backdrop-filter: blur(10px);
  background-color: ${alpha("#fff", 0.1)};
  border: 1px solid ${alpha("#fff", 0.2)};
  :hover {
    scale: 1.04;
    // box-shadow: rgba(0, 0, 0, 0.16) -40px 40px 80px;
  }
`;
