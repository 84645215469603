import React from "react";
import { Landing } from "../pages/Landing";
import { Pricing } from "../pages/Pricing";
import { BankingPlatform } from "../pages/BankingPlatform";
import { Wallet } from "../pages/Wallet";
import { Telecom } from "../pages/Telecom";
import { Business } from "../pages/Business";
import { Finance } from "../pages/Finance";
import { Contact } from "../sections/Contact";
import { Engineering } from "../pages/Engineering"; 
import { TermsService } from "../pages/TermsService";
import { GreenInvesting} from "../pages/GreenInvesting";

import {
  contactLink,
  BankingLink,
  WalletLink,
  TelecomLink,
  BusinessLink,
  FinanceLink,
  pricingLink,
  EngineeringLink,
  termsLink,
  GreenInvestingLink,
} from "./links";

export const routes = [
  {
    path: "/",
    component: <Landing />,
  },
  {
    path: pricingLink,
    component: <Pricing />,
  },
  {
    path: contactLink,
    component: <Contact />,
  },
  {
    path: BankingLink,
    component: <BankingPlatform />,
  },
  {
    path: WalletLink,
    component: <Wallet />,
  },
  {
    path: TelecomLink,
    component: <Telecom />,
  },
  {
    path: BusinessLink,
    component: <Business />,
  },
  {
    path: FinanceLink,
    component: <Finance />,
  },
  {
    path: EngineeringLink,
    component: <Engineering />,
  },
  {
    path: GreenInvestingLink,
    component: <GreenInvesting />,
  },
  {
    path: termsLink,
    component: <TermsService />,
  },
];
