import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { DashImg } from "../assets/img/Images";
// import { HeaderButton } from "../components/buttons/HeaderButton";
import { scrollToId } from "../utils/Utils";
import { Link } from "react-router-dom";

export const Header = () => {
  const [headerText, setHeaderText] = useState("");

  // Array of header phrases to cycle through
  const headerPhrases = ["SACCO Core-Banking", "Investing Sustainably", "Connecting Digitally"];

  useEffect(() => {
    // Function to switch header text every 5 seconds
    const interval = setInterval(() => {
      // Rotate through header phrases
      const currentIndex = headerPhrases.indexOf(headerText);
      const nextIndex = (currentIndex + 1) % headerPhrases.length;
      setHeaderText(headerPhrases[nextIndex]);
    }, 5000); // Switch every 5 seconds

    return () => clearInterval(interval);
  }, [headerText, headerPhrases]);

  return (
    <Wrapper id="home">
      <Container className="flexSpaceCenter">
        {/* Left */}
        <LeftSide className="flex flexColumn gap20" style={{ height: "100%", paddingLeft: "5vw" }}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            {/* Conditional rendering based on screen size */}
            <h1 className="extraBold font60" style={{ color: "#FFFFFF", fontSize: "50px" }}>
              <DesktopText>We Innovate </DesktopText>
              <RotatingText>{headerText}</RotatingText> 
            </h1>
          </div>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800" style={{ color: "#FFFFFF" }}>
            <HeaderP className="font14">
              <p>
                We transform SACCOs & Microfinance institutions with cutting-edge core banking solutions, empowering sustainable investments, and enhancing safe digital 
                inclusion — unlocking possibilities for all. Our mission is to revolutionize finance and connect you.
              </p>
              <br />
              <p>
                <b>SACCO Management | Investment Solutions | Everpesa Mobile</b>
              </p>
            </HeaderP>
          </div>

          <div>
            {/* <HeaderButton onClick={() => scrollToId("about")}>Explore More</HeaderButton> */}
            <HeaderButton className="font24" to="/contact">Request a demo</HeaderButton>
          </div>
        </LeftSide>

        {/* Right */}
        <RightSide>
          <AnimatedImg src={DashImg} alt="Interactive Image" />
        </RightSide>
      </Container>
    </Wrapper>
  );
};

// Styled components


const HeaderButton = styled(Link)`
  padding: 12px 24px;
  background-color: #fff;
  color: #003CBE;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d4af37; /* Darker shade of gold on hover */
  }
`;

// const Wrapper = styled.section`
//   padding-top: 90px;
//   background-color: #003CBE;
//   width: 100%;
//   margin: 0;
//   min-height: 100vh;
//   color: #FFFFFF;
//   display: flex;
//   align-items: center;
//   overflow: hidden; /* Ensures no overflow beyond the screen edges */
//   @media (max-width: 960px) {
//     flex-direction: column;
//     min-height: 100%;
//   }
// `;

const Wrapper = styled.section`
  padding-top: 90px;
  background-color: #003CBE;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  overflow: hidden; /* Ensures no overflow beyond the screen edges */
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
    padding-top: 20px; /* Adjusted padding for smaller screens */
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const RotatingText = styled.span`
  color: gold;
  transition: opacity 1s ease-in-out;
`;

const DesktopText = styled.span`
  @media (max-width: 960px) {
    display: none;
  }
`;

const MobileText = styled.span`
  display: none;
  @media (max-width: 960px) {
    display: inline;
    color: gold;
  }
`;

// const LeftSide = styled.div`
//   width: 50%;
//   height: 100%;
//   padding-left: 50px; /* Adjusted padding-left */
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 1;
//     margin: 50px 0;
//     text-align: center;
//     padding-left: 20px; /* Adjust for smaller screens if needed */
//   }
//   @media (max-width: 560px) {
//     margin: 80px 0 50px 0;
//   }
// `;

const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  padding-left: 50px; /* Adjusted padding-left */
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 20px 0;
    text-align: center;
    padding-left: 20px; /* Adjust for smaller screens if needed */
  }
  @media (max-width: 560px) {
    margin: 50px 0 20px 0; /* Adjusted margins */
    padding-left: 10px; /* Further adjustment for very small screens */
  }
`;


// const RightSide = styled.div`
//   width: 50%;
//   height: 100%;
//   overflow: hidden;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 2;
//     margin-top: 70px;
//     margin-bottom: 80px;
//     justify-content: center;
//   }
// `;

const RightSide = styled.div`
  width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin-top: 20px; /* Adjusted margin-top */
    margin-bottom: 20px; /* Adjusted margin-bottom */
    justify-content: center;
  }
  @media (max-width: 560px) {
    margin-top: 20px;
    margin-bottom: 20px; /* Further adjustment for very small screens */
  }
`;

// Animation for interactive image movement
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

// const AnimatedImg = styled.img`
//   width: 100%;
//   height: auto;
//   margin: 0;
//   box-shadow: none;
//   animation: ${bounceAnimation} 3s ease-in-out infinite; /* Adjust animation properties as needed */
//   @media (max-width: 960px) {
//     max-width: 90%;
//   }
// `;

const AnimatedImg = styled.img`
  width: 100%;
  height: auto;
  margin: 0;
  box-shadow: none;
  animation: ${bounceAnimation} 3s ease-in-out infinite; /* Adjust animation properties as needed */
  @media (max-width: 960px) {
    max-width: 90%;
  }
  @media (max-width: 560px) {
    max-width: 80%; /* Adjusted max-width for very small screens */
  }
`;

// const HeaderP = styled.div`
//   max-width: 470px;
//   padding: 15px 0 20px 0;
//   line-height: 1.5rem;
//   @media (max-width: 960px) {
//     padding: 15px 0 20px 0;
//     text-align: center;
//     max-width: 100%;
//   }
// `;

const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 20px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 20px 0;
    text-align: center;
    max-width: 100%;
  }
  @media (max-width: 560px) {
    padding: 10px 0 15px 0; /* Further adjustment for very small screens */
  }
`;