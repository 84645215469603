import { ChevronRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export const GenericButton = (props) => {
  const { className, children, to, onClick } = props;
  const navigate = useNavigate();
  return (
    <Button
      className={className}
      sx={{ borderRadius: 10, height: 45 }}
      variant="contained"
      disableElevation
      endIcon={<ChevronRight />}
      onClick={onClick ? onClick : to ? () => navigate(to) : null}
    >
      {children}
    </Button>
  );
};
