import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import Footer from "../sections/Footer";
import { PatternStrip } from "../sections/PatternStrip";
import { Telecommunication } from "../sections/Telecommunication";
import { contactLink } from "../constants/links";

export const HeaderSection = () => (
  <Wrapper className="gradient1 combDark">
    <div className="container whiteColor">
      <LeftSide className="flexColumn">
        <GapWrapper gap={10}>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="1500">
            <h1 className="extraBold font60">
              Everpesa <HighlightSpan>Mobile</HighlightSpan>
            </h1>
          </div>
          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="600">
            <div className="font18 semiBold">
              Building a <b>Connected</b> Tomorrow, Today
            </div>
          </div>
        </GapWrapper>

        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
          <TitleP className="font14">
            Everpesa Mobile enables businesses and individuals get connected by providing 
            information and communications technology (ICT) infrastructure and smart devices. 
            We provide a range of services that include: IT equipment and related services, 
            Network services, Smartphone Devices, Cloud services, and Security solutions.
          </TitleP>
        </div>

        {/* <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <HeaderButton to={contactLink}>Start Today</HeaderButton>
        </div> */}
        <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1000">
          <Button onClick={() => window.location.href = "https://mobile.everpesa.com"}>
            Get Connected Today
          </Button>
        </div>
      </LeftSide>
    </div>
  </Wrapper>

);

export const Telecom = () => {
  return (
    <>
      <HeaderSection />
      <Telecommunication />
      <PatternStrip />
      <Footer />
    </>
  );
};
const Wrapper = styled.section`
  padding-top: 90px;
  padding-bottom: 50px;
  width: 100%;
  min-height: 100vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
  background: url('./african-market.jpg');
  background-size: cover; /* Ensure the background image covers the whole viewport */
  background-position: center; /* Center the background image */
  color: white; /* Set text color to white */
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;


const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #003CBE;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #0056b3;
  }
`;