import { Chip, Divider, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import {
  GapWrapper,
  HeaderTitle,
  HighlightSpan,
  TitleP,
} from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { socialLinks } from "../constants/links";
import logoBlue from "../assets/svg/logo/logo-blue.png";
import Footer from "./Footer";
import { Steps } from "antd";
import { LocalPhone, LocationOn, Mail } from "@mui/icons-material";
import { addressData } from "../constants/siteData";

export const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    phone: "",
    email: "",
    organization: "",
    message: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Basic client-side validation
    if (!values.name || !values.email) {
      // Display an error message to the user and return early
      console.error('Name and email are required.');
      // You can set a state to show an error message on the UI
      return;
    }

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycby_LQyogHbvnv_OVhrsjhOgRRoG7AuW529RhAuUPDIKiyvRIj5Ufh5bgiCldI3Kt03CHg/exec', // Replace with your Google Apps Script web app URL
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'no-cors',
          body: JSON.stringify(values),
        }
      );

      // Assume success unless an error occurs
      console.log('Form submission successful');
      // Optionally, reset the form fields or show a success message to the user.
      setValues({
        name: '',
        phone: '',
        email: '',
        organization: '',
        message: '',
      });

    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <Wrapper className="poka-pattern flexCenter">
        <GridWrapper className="container">
          <LeftSide>
            <GapWrapper gap={20}>
              {/* <img src={Ills1} width="50%" alt="clients" /> */}
              <div>
                <Chip
                  label="Everpesa Technologies"
                  color="primary"
                  data-aos="zoom-in-up"
                  data-aos-duration="1000"
                />
              </div>
              <GapWrapper gap={10}>
                <HeaderTitle>
                  {/*<HighlightSpan>Contact</HighlightSpan> Us*/}
                  <HighlightSpan>Contact</HighlightSpan> Sales
                </HeaderTitle>
                <p
                  data-aos="fade-up"
                  data-aos-delay="500"
                  data-aos-duration="1000"
                  className="semiBold"
                >
                  Sign Up with us today and enjoy the best of our services.
                </p>
              </GapWrapper>

              <TitleP
                className="font14"
                data-aos="fade-up"
                data-aos-delay="500"
                data-aos-duration="1500"
              >
                We are always available to answer your questions and help you
                get started.
              </TitleP>

              <div
                style={{ paddingTop: 20 }}
                data-aos="fade-up"
                data-aos-delay="800"
                data-aos-duration="1500"
              >
                <Steps
                  direction="vertical"
                  current={3}
                  items={[
                    {
                      title: "Address",
                      description: addressData[0].text,

                      icon: <LocationOn />,
                    },
                    {
                      title: "Phone",
                      description: addressData[1].text,
                      icon: <LocalPhone />,
                    },
                    {
                      title: "Phone",
                      description: addressData[2].text,
                      icon: <LocalPhone />,
                    },
                    {
                      title: "Email",
                      description: addressData[3].text,
                      icon: <Mail />,
                    },
                  ]}
                />
              </div>
            </GapWrapper>
          </LeftSide>

          <BoxWrapper
            className="whiteBg radius8"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="1500"
          >
            <form onSubmit={handleSubmit}>
              <GapWrapper gap={20} className="textCenter">
                <div className="flexCenter">
                  <img src={logoBlue} alt="logo" style={{ height: 50 }} />
                </div>
                <InnerGrid>
                  <input
                    type="text"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange('name')}
                  />
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={values.phone}
                    onChange={handleChange('phone')}
                  />
                  <input
                    type="email"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange('email')}
                  />
                  <input
                    type="text"
                    placeholder="Organization"
                    value={values.organization}
                    onChange={handleChange('organization')}
                  />
                  <input
                    placeholder="Your message"
                    value={values.message}
                    onChange={handleChange('message')}
                  />
                  <button type="submit" className="custom__button">
                    Submit
                  </button>

                <Divider />

                <div>
                  <p className="font12" style={{ marginBottom: 10 }}>
                    Follow us on social media
                  </p>
                  {socialLinks.map(({ Icon, link }, i) => (
                    <IconButton key={i}>
                      <a
                        href={link}
                        className="animate mainColor flex lightLink"
                      >
                        <Icon />
                      </a>
                    </IconButton>
                  ))}
                </div>
                </InnerGrid>
              </GapWrapper>
            </form>
          </BoxWrapper>
        </GridWrapper>
      </Wrapper>
      <Footer />
    </>
  );
};

// ... rest of your component

const GridWrapper = styled.div`
  padding-top: 50px;
  display: grid;
  height: 100%;
  align-items: center;
  gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const InnerGrid = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }

  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px; // Add this line for curved borders
  }

  button[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const Wrapper = styled.section`
  min-height: 100vh;
  padding: 80px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;

const BoxWrapper = styled.div`
  width: 100%;
  padding: 40px;
  box-shadow: rgba(0, 0, 0, 0.13) -60px 40px 80px;
  @media (max-width: 860px) {
    padding: 20px;
  }
`;

const LeftSide = styled.div`
  @media (max-width: 960px) {
    margin-top: 50px;
    text-align: center;
  }
`;





//https://script.google.com/macros/s/AKfycby_LQyogHbvnv_OVhrsjhOgRRoG7AuW529RhAuUPDIKiyvRIj5Ufh5bgiCldI3Kt03CHg/exec
//AKfycby_LQyogHbvnv_OVhrsjhOgRRoG7AuW529RhAuUPDIKiyvRIj5Ufh5bgiCldI3Kt03CHg
