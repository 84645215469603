import React from "react";
import styled from "styled-components";
import { GapWrapper, HighlightSpan, TitleP } from "../components/styled/Styled";
import { HeaderButton } from "../components/buttons/HeaderButton";
import { contactLink } from "../constants/links";
import dashboard from "../assets/img/screens/dashboard.png";

export const Banking = () => {
  return (
    <div className="">
      <Wrapper className="container flexSpaceCenter" id="SACCO">
        <LeftSide className="flex flexColumn">
          <GapWrapper gap={10}>
            <div
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <h1 className="extraBold font60">
                <HighlightSpan>Safari</HighlightSpan>Banking Platform
              </h1>
            </div>
            <div
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay="600"
            >
              <div className="font18 extraBold">
                Empowering SACCOs with Unrivaled Security and Efficiency
              </div>
            </div>
          </GapWrapper>

          <div data-aos="fade-up" data-aos-duration="1500" data-aos-delay="800">
            <TitleP className="font14">
              SafariBanking is a sophisticated <b>SACCO and Microfinance Management 
              Application</b>  tailored to the unique needs of SACCOs, 
              where <b>security </b> and <b>robustness </b>are paramount. 
              <br />
              <br />
              Our platform serves as the bedrock of a thriving financial community, 
              offering a comprehensive suite of features designed to streamline 
              operations, foster financial inclusion, and fuel growth.
            </TitleP>
          </div>

          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <HeaderButton to={contactLink}>Start Today</HeaderButton>
          </div>
        </LeftSide>
        <RightSide>
          <div
            data-aos="fade-up"
            data-aos-duration="1500"
            data-aos-delay="1000"
          >
            <img src={dashboard} alt="safaribanking system" />
          </div>
        </RightSide>
      </Wrapper>
    </div>
  );
};
const Wrapper = styled.section`
  padding-top: 70px;
  width: 100%;
  min-height: 90vh;
  @media (max-width: 960px) {
    flex-direction: column;
    min-height: 100%;
  }
`;

const LeftSide = styled.div`
  width: 50%;
  gap: 40px;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;

// const RightSide = styled.div`
//   width: 50%;
//   padding: 30px;
//   @media (max-width: 960px) {
//     width: 100%;
//     order: 1;
//   }
// `;

const RightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  img {
    max-width: 100%;
    height: auto;
    width: auto;
    @media (max-width: 768px) {
      width: 80%; // Adjust as needed for mobile
    }
  }
`;
