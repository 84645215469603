
import React from "react";
import styled from "styled-components";
import { productsData } from "../constants/siteData";
import { Link } from "react-router-dom"; // Assuming you use React Router for navigation
import {
  HighlightSpan,
} from "../components/styled/Styled";

const CardItem = ({ data, index }) => {
  const { title, Icon, desc } = data;
  const productPaths = ["banking-platform", "everpesa-invest", "telecom"]; // paths for each product

  return (
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay={index === 1 ? 200 : 1000}
    >
      <CardWrapper
        index={index}
        className="flexCenter flexColumn textCenter radius8 animate"
      >
        <Link to={`/${productPaths[index]}`} style={{ textDecoration: "none" }}>
          <Icon style={{ height: 50, width: 50 }} />
          <h2 style={{ margin: "64px 0px 16px" }}>{title}</h2>
        </Link>
        <p className="font14" style={{ lineHeight: 1.8 }}>
          {desc}
        </p>
      </CardWrapper>
    </div>
  );
};

export const Products = () => {
  return (
    <Wrapper id="discover">
      <div className="container">
        <div className="textCenter" style={{ paddingBottom: 50, lineHeight: 2 }}>
          <h2 className="font30">
            <HighlightSpan>Discover</HighlightSpan> Our Products
          </h2>
        </div>

        <GridWrapper>
          {productsData.map((val, i) => (
            <CardItem data={val} key={i} index={i} />
          ))}
        </GridWrapper>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0 120px 0;
  @media (max-width: 960px) {
    padding: 70px 0;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  @media (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

const CardWrapper = styled.div`
  padding: 40px 20px;
  line-height: 2rem;
  height: 100%;
  box-shadow: ${(prop) =>
    prop.index === 1 && "rgba(145, 158, 171, 0.4) -40px 40px 80px"};
  :hover {
    box-shadow: rgba(145, 158, 171, 0.4) -40px 40px 80px;
    scale: 1.02;
  }
  @media (max-width: 860px) {
    padding: 30px 20px;
    box-shadow: rgba(145, 158, 171, 0.4) -40px 40px 80px;
  }
`;