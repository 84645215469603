import {
  Instagram,
  LinkedIn,
  Twitter,
  Facebook,
  YouTube,
} from "@mui/icons-material";

export const BankingLink = "/banking-platform";
export const WalletLink = "/everpesa-invest";
export const TelecomLink = "/telecom";
export const BusinessLink = "/business-solutions";
export const FinanceLink = "/finance";
export const contactLink = "/contact";
export const pricingLink = "/pricing";
export const EngineeringLink = "/engineering";
export const termsLink = "/terms-of-service";
export const GreenInvestingLink = "/green-investing";

export const navLinks = [
  {
    label: "Home",
  },
  // {
  //   label: "Discover",
  // },
  {
    label: "Banking Platform",
    to: BankingLink,
  },
  {
    label: "Investing Platform",
    to: WalletLink,
  },
  {
    label: "Green Investing",
    to: GreenInvestingLink
  },
  
  {
    label: "Business Solutions",
    children: [
      {
        label: "Everpesa Mobile",
        to: TelecomLink,
      },
      {
        label: "Green Finance",
        to: FinanceLink,
      },
      {
        label: "Tech Consulting",
        to: BusinessLink,
      },
    ],
  },
  // {
  //   label: "Pricing",
  //   to: pricingLink,
  // },
  // {
  //   label: "FAQs",
  // },
];

const TikTokIcon = ({ color = "#ffffff", size = 24 }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width={size}
      height={size}
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

export const socialLinks = [
  {
    Icon: Twitter,
    link:
      "https://twitter.com/everpesa_app/",
  },
  {
    Icon: Instagram,
    link: "https://www.instagram.com/everpesa/",
  },
  {
    Icon: LinkedIn,
    link: "https://www.linkedin.com/company/everpesa/",
  },
  {
    Icon: Facebook,
    link: "https://www.facebook.com/profile.php?id=100092325456479",
  },
  {
    Icon: YouTube,
    link: "https://www.youtube.com/@everpesa",
  },
  {
    Icon: TikTokIcon,
    link: "https://tiktok.com/@everpesa",
  }
];

export const locationLink =
  "https://www.google.com/maps";
