import React from "react";
import {
  HeaderInfo,
  HeaderTitle,
} from "../components/styled/Styled";
import styled from "styled-components";
import { whyGreenData } from "../constants/siteData";
import { ServiceBox } from "../components/service-box/ServiceBox";

export const WhyGreen = () => {
  return (
    <Wrapper>
      <div className="container" id="why">
        <HeaderInfo className="flex flexColumn gap20">
          <p
            className="font14 mainColor semiBold"
            data-aos="fade-up"
            data-aos-duration="1500"
          >
            How does Green Investment work on Everpesa?
          </p>

          <div className="flex wrap gap20">
            <div
              style={{ flexGrow: 2 }}
              data-aos="fade-up"
              data-aos-delay="300"
              data-aos-duration="1500"
            >
              <HeaderTitle>
                Join us in making a difference. Start investing with Everpesa and be at the forefront of green finance in Africa.
              </HeaderTitle>
            </div>
          </div>
        </HeaderInfo>

        <ServiceBoxRow
          className="flex gap20"
          data-aos="fade-up"
          data-aos-delay="700"
          data-aos-duration="1500"
        >
          {whyGreenData.map(({ Icon, title, desc }) => (
            <ServiceBoxWrapper key={title}>
              <ServiceBox Icon={Icon} title={title} desc={desc} />
            </ServiceBoxWrapper>
          ))}
        </ServiceBoxRow>
      </div>
    </Wrapper>
  );
};

const ServiceBoxRow = styled.div`
  margin-top: 40px;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 25%;
  @media (max-width: 860px) {
    width: 100%;
  }
`;
const Wrapper = styled.section`
  width: 100%;
  padding: 150px 0;

  @media (max-width: 860px) {
    padding: 100px 0;
  }
`;
