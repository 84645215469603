import React from "react";
import styled from "styled-components";
import { bankFeatures } from "../constants/siteData";
import { TeamBox } from "../components/team/TeamBox";
import {
  HeaderTitle,
  HighlightSpan,
  TitleP,
} from "../components/styled/Styled";
import { GenericButton } from "../components/buttons/GenericButton";
import { BankingLink } from "../constants/links";
import { getLocation } from "../utils/Utils";

export const CoreBanking = () => {
  return (
    <Wrapper id="sacco">
      <BackgroundWrapper className="" />
      <div className="container textCenter flexCenter flexColumn gap20">
        <HeaderTitle>
          <HighlightSpan>SafariBanking </HighlightSpan> Your SACCO's Core-Banking Software
        </HeaderTitle>

        <TitleP
          className="font14"
          data-aos="fade-up"
          data-aos-delay="300"
          data-aos-duration="1500"
        >
          SafariBanking is the cornerstone of our <b>fintech</b> ecosystem, tailored to meet 
          the unique needs of <b>SACCOs</b> and <b>microfinance</b> institutions. It serves as a 
          comprehensive platform that streamlines operations, fosters financial inclusion, 
          and fuels growth within financial communities.
        </TitleP>

        <br />

        <TeamGridWrapper>
          {bankFeatures.map((val, i) => (
            <div
              key={i}
              data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-delay={i * 100}
            >
              <TeamBox data={val} />
            </div>
          ))}
        </TeamGridWrapper>

        {getLocation() === "/" && (
          <div className="textCenter">
            <GenericButton className="lightGoldBg" to={BankingLink}>
              SafariBanking
            </GenericButton>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  padding: 80px 0;
  min-height: 50vh;
  @media (max-width: 960px) {
    padding: 40px 0;
    flex-direction: column;
  }
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  // opacity: 0.3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const TeamGridWrapper = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(5, 1fr);

  @media (max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 760px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 560px) {
    grid-gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 360px) {
    grid-template-columns: 1fr;
  }
`;
