import { ReactComponent as iBank} from "./bank-svgrepo-com.svg";
import { ReactComponent as iWallet } from "./wallet-2-svgrepo-com.svg";
import { ReactComponent as iDollar } from "./dollar-sign-svgrepo-com.svg";
import { ReactComponent as iTelecom } from "./telecom-svgrepo-com.svg";

import { ReactComponent as iDesign } from "./ic_design.svg";
import { ReactComponent as iBrand } from "./ic_make_brand.svg";
import { ReactComponent as iMission } from "./ic_mission.svg";
import { ReactComponent as iAccounting } from "./features/bank.svg";
import { ReactComponent as iBell } from "./features/envelope-dot.svg";
import { ReactComponent as iCoins } from "./features/coins.svg";
import { ReactComponent as iCard } from "./features/credit-card.svg";
import { ReactComponent as iHelp } from "./features/interrogation.svg";
import { ReactComponent as iSmart } from "./features/mobile-notch.svg";
import { ReactComponent as iSave } from "./features/sack-dollar.svg";
import { ReactComponent as iStats } from "./features/stats.svg";
import { ReactComponent as iUsers } from "./features/users.svg";
import { ReactComponent as iAccountant } from "./illustrations/accountant.svg";
import { ReactComponent as iManage } from "./illustrations/manage.svg";

/*
import { ReactComponent as iSales } from "./illustrations/sales-dashboard.svg";
import { ReactComponent as iSaSS } from "./illustrations/sass-dashboard.svg";
*/

export const BankSVG = iBank;
export const WalletSVG = iWallet;
export const DollarSVG = iDollar;
export const TelecomSVG = iTelecom;

export const DesignSVG = iDesign;
export const BrandSVG = iBrand;
export const MissionSVG = iMission;
export const AccountingSVG = iAccounting;

export const BellSVG = iBell;
export const CoinsSVG = iCoins;
export const CardSVG = iCard;
export const HelpSVG = iHelp;
export const SmartSVG = iSmart;
export const SaveSVG = iSave;
export const StatsSVG = iStats;
export const UsersSVG = iUsers;

// Logo

// Illustrations
export const AccountantSVG = iAccountant;
export const ManageSVG = iManage;
/*
export const SalesSVG = iSales;
export const SaSSSVG = iSaSS;
*/