import styled from "styled-components";
import { RowWrapper, Wrapper } from "../components/styled/Styled";
import { IconButton } from "@mui/material";
import {
  contactLink,
  BankingLink,
  TelecomLink,
  BusinessLink,
  FinanceLink,
  pricingLink,
  socialLinks,
  termsLink,
  EngineeringLink,
  GreenInvestingLink,
} from "../constants/links";
import { addressData } from "../constants/siteData";
import logoWhite from "../assets/svg/logo/logo-white.png";

export const getCurrentYear = () => {
  return new Date().getFullYear();
};

const ContactItem = ({ text, Icon }) => {
  return (
    <div
      className="whiteColor font12 flexNullCenter gap10"
      style={{ paddingBottom: "10px" }}
    >
      <Icon fontSize="small" />
      <StyleP2>{text}</StyleP2>
    </div>
  );
};

const LinkItem = ({ children, to }) => (
  <div style={{ paddingBottom: 5 }}>
    <a
      className="whiteColor animate pointer font13 lightLink"
      href={to}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </a>
  </div>
);

export default function Footer() {
  return (
    <Wrapper className="darkGreenBg poka-pattern-dark">
      <div className="container">
        <InnerWrapper className="flexSpaceNull">
          {/* Logo */}
          <SectionWrapper className="flexCenter">
            <div className="flexCenter">
              <img src={logoWhite} alt="logo" style={{ height: 50 }} />
            </div>
          </SectionWrapper>

          {/* Contact */}
          <SectionWrapper>
            <Title>Find Us</Title>

            {addressData.map((val, i) => (
              <ContactItem key={i} text={val.text} Icon={val.icon} />
            ))}
          </SectionWrapper>

          {/* Products */}
          <SectionWrapper>
            <Title>Products</Title>
            
            <LinkItem to={GreenInvestingLink}>Green Investing</LinkItem>
            <LinkItem to={FinanceLink}>Green Business Finance</LinkItem>
            <LinkItem to={BankingLink}>Banking Platform</LinkItem>
            <LinkItem to={TelecomLink}>Everpesa Mobile</LinkItem>
            <LinkItem to={BusinessLink}>Business Solutions</LinkItem>
          </SectionWrapper>
          {/* Get Started */}
          <SectionWrapper>
            <Title>Useful Links</Title>
            <LinkItem to={pricingLink}>Pricing Plans</LinkItem>
            <LinkItem to={contactLink}>Contact Us</LinkItem>
            <LinkItem to={contactLink}>Help Center</LinkItem>
            <LinkItem to={EngineeringLink}>Engineering</LinkItem>
            <LinkItem to={termsLink}>Terms of Service</LinkItem>
          </SectionWrapper>
        </InnerWrapper>
      </div>

      {/* Bottom section */}
      <div
        className="flex"
        style={{
          borderTop: "1px solid rgb(255, 255, 255, 0.1)",
          backdropFilter: "blur(5px)",
        }}
      >
        
        <RowWrapper
          className="flexSpaceCenter container"
          style={{ padding: 20 }}
        >
          <StyleP className="whiteColor font12">
            © 2023 - {getCurrentYear()} | Everpesa Technologies Limited. All Rights
            Reserved.
            <br />
            <br />
            <StyleP>
            {socialLinks.map(({ Icon, link }, i) => (
              <IconButton key={i}>
                <a href={link} className="animate whiteColor flex lightLink">
                  <Icon />
                </a>
              </IconButton>
            ))}
          </StyleP>
          <br />
          <br />

            Everpesa Technologies Limited is a financial technology and software company and is not a bank. 
            Banking services provided by partner banks. By using this website, you accept and agree to Everpesa’s 
            Terms of Use and Privacy Policy.
            <br />  
            <br />
            
          </StyleP>

        </RowWrapper>
      </div>
    </Wrapper>
  );
}

const Title = styled.h4`
  padding-bottom: 30px;
  color: white;
`;

const InnerWrapper = styled.div`
  padding: 70px 0;
  @media (max-width: 550px) {
    padding: 50px 0;
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
    width: 100%;
    text-align: center;
  }
`;

const StyleP2 = styled.p`
  max-width: 300px;
`;

const SectionWrapper = styled.div`
  width: 20%;
  @media (max-width: 550px) {
    width: 100%;
    padding: 20px;
  }
`;
