import React from "react";

import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import {
  contactLink,
  BankingLink,
  FinanceLink,
  WalletLink,
  TelecomLink,
  BusinessLink,
  pricingLink,
} from "../../constants/links";

// Creating our own theme
const botColor = "#003CBE";
const theme = {
  background: "white",
  headerBgColor: botColor,
  headerFontSize: "20px",
  botBubbleColor: botColor,
  headerFontColor: "white",
  botFontColor: "white",
  userBubbleColor: "#F2f2f2",
  userFontColor: "black",
};

// Set some properties of the bot
const config = {
  // botAvatar: Favicon,
  floating: true,
};

const steps = [
  {
    id: "0",
    message: "Hello, Welcome to Everpesa!",
    trigger: "1",
  },
  {
    id: "1",
    message: "How can i help you?",
    trigger: "options",
  },
  {
    id: "options",
    options: [
      { value: "everpesa", label: "Banking Platform", trigger: "endpoint" },
      { value: "mobile", label: "Everpesa Mobile", trigger: "endpoint" },
      { value: "business", label: "Business Solutions", trigger: "endpoint" },
      { value: "finance", label: "Finance Solutions", trigger: "endpoint"},
      { value: "wallet", label: "Wallet Platform", trigger: "endpoint" },
      //{ value: 1, label: "Pricing Plans", trigger: "pricing_plans" },
      { value: "contact", label: "Send us a message", trigger: "endpoint" },
    ],
  },

  {
    id: "pricing_plans",
    options: [
      { value: "free_plan", label: "Free Plan", trigger: "endpoint" },
      { value: "silver_plan", label: "Silver Plan", trigger: "endpoint" },
      { value: "gold_plan", label: "Gold Plan", trigger: "endpoint" },
    ],
  },

  {
    id: "endpoint",
    message: "You will be redirected shortly!",
    end: true,
  },
];

function handleEnd({ steps, values }) {
  console.log(values);
  let value = values[0] || values[1];
  if (value.includes("plan")) {
    window.location.replace(pricingLink);
  } else if (value.includes("everpesa")) {
    window.location.replace(BankingLink);
  } else if (value.includes("mobile")) {
    window.location.replace(TelecomLink);
  } else if (value.includes("business")) {
    window.location.replace(BusinessLink);
  } else if (value.includes("finance")) {
    window.location.replace(FinanceLink);
  } else if (value.includes("wallet")) {
    window.location.replace(WalletLink);
  } else if (value.includes("contact")) {
    window.location.replace(contactLink);
  }
  // alert(`Chat handleEnd callback! Number: ${values[1]}`);
}

export const EverpesaBot = () => {
  return (
    <ThemeProvider theme={theme}>
      <ChatBot
        handleEnd={handleEnd}
        headerTitle={<p className="font15">EverPesa Bot</p>}
        steps={steps}
        {...config}
      />
    </ThemeProvider>
  );
};
